import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: '3b6fea7d-8c22-454f-9cac-0c6f056d3a87',
  clientToken: 'pub9b35c3f77f076308c88ed7663eec31e7',
  site: 'datadoghq.eu',
  env: process.env.NODE_ENV,
  service: 'gregate',
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'allow',
})

datadogRum.startSessionReplayRecording()
