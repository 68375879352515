import Vue from 'vue'
import './registerServiceWorker'

// Set up Axios
import axios from 'axios'
axios.defaults.baseURL = '/api'

// Add Sentry
import './sentry'
import './datadog'
// Vue plugins
import VueMeta from 'vue-meta'
import VBodyScrollLock from 'v-body-scroll-lock'
import VueClosable from 'vue-closable'
import VueProgressBar from 'vue-progressbar'

// Local imports
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.pcss'
import { POSPrintPlugin } from '@/plugins/epos-sdk'
import { FirestorePlugin } from '@/plugins/firestore'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(FirestorePlugin)
Vue.use(POSPrintPlugin)
Vue.use(VBodyScrollLock)
Vue.use(VueClosable)
Vue.use(VueProgressBar, {
  color: 'rgba(59, 130, 246)',
  failedColor: 'red',
  height: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
