import Vue from 'vue'
import { IS_PROD_BUILD } from '@/utils/globals'
import * as Sentry from '@sentry/vue'

export type SentryT = typeof Sentry

Sentry.init({
  Vue,
  dsn: IS_PROD_BUILD
    ? 'https://1d454a7207e540d7ac35e8cbf347ed87@o1408653.ingest.sentry.io/4503931200536576'
    : undefined,
  logErrors: true,
  environment: process.env.NODE_ENV,
})

// Add globally
Vue.prototype.$sentry = Vue.$sentry = Sentry
